import AppDownload from "./components/app-download";
import ServiceSummary from "./components/service-summary";
import Banner from "./components/banner";
import AppSummary from "./components/app-summary";
import AppFeature from "./components/app-feature";
import AppTip from "./components/app-tip";
import AppFeatureMobile from "./components/app-feature-mobile";
import History from "./components/history";
interface Props {
  clickScrollNavigation: (target: string) => void;
  getActivateTarget: () => string;
}
const CompanyBody = () => {
  return (
    <div style={{ overflow: "hidden", maxWidth: "100vw" }}>
      <AppDownload />
      <AppSummary />      
      <ServiceSummary />
      <AppTip />
      <AppFeature />
      <AppFeatureMobile />
      <History />
      <Banner />
    </div>
  );
};

export default CompanyBody;
