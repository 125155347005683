import React from "react";
import {
  StyledTitle,
  StyledWrapper,
  StyledBr,
  StyledResponsiveBr,
  StyledYoutubeWrapper,
} from "./styles";

const AppSummary = () => {
  return (
    <StyledWrapper>
      <StyledTitle>
        “군부대 식품 발주, 이제 더 이상 복잡하지 않습니다!”
        <StyledResponsiveBr />
        <StyledBr /> 이지마스를 통해 군부대에 간편하게 제품을 알리고,{" "}
        <StyledResponsiveBr />
        <StyledBr />
        발주부터 납품까지 모든 과정을 효율적으로 관리하세요.
        <StyledYoutubeWrapper>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HoqY9KdRVTE?si=tP91xnJBe7EjfXhT" title="EASY MAS" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </StyledYoutubeWrapper>
      </StyledTitle>
    </StyledWrapper>
  );
};

export default AppSummary;
