import React from "react";
import {
  StyledTitle,
  StyledWrapper,
  StyledBr,
  StyledResponsiveBr,
  StyledYoutubeWrapper,
} from "./styles";

const AppSummary = () => {
  return (
    <StyledWrapper>
      <StyledTitle>
        “군 급식 발주, 이지마스로 더 간편하게!” <StyledBr />
        <StyledResponsiveBr />
        식품 검색, 발주 일정, 납품 이력까지
        <StyledResponsiveBr /> 한 곳에서 관리하세요.
        <StyledResponsiveBr />
        <StyledBr /> 이지마스로 군 급식 발주를
        <StyledResponsiveBr /> 더 효율적으로 진행해 보세요!
        <StyledYoutubeWrapper>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HoqY9KdRVTE?si=tP91xnJBe7EjfXhT" title="EASY MAS" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </StyledYoutubeWrapper>
      </StyledTitle>
    </StyledWrapper>
  );
};

export default AppSummary;