import theme from "../../../../styles/theme";
import {
  Button1,
  Headline2,
  Headline3White,
  Paragraph2,
} from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  background: ${({ theme }) => theme.color.presentative};
  /* margin-top: 130px; */

  @media screen and (max-width: 875px) {
    height: auto;
    padding: 120px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: auto;
    padding: 80px 24px;
  }
`;

const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > div {
    margin-top: 42px;
    display: flex;
    gap: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    & > div {
      margin-top: 33px;
      & > img {
        width: 120px;
        height: 36px;
      }
      & > img:first-child {
        width: 100px;
        height: 34px;
      }
    }
  }
`;

const StyledTitle = styled(Headline3White)`
  text-align: center;

  @media screen and (max-width: 875px) {
    font-size: 34px !important;
    line-height: 60px !important;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 20px !important;
    line-height: 36px !important;
    white-space: pre-line;
    word-break: keep-all;
  }
`;

const StyledSubTitle = styled(Paragraph2)`
  margin-top: 8px;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    margin-top: 4px;
  }
`;

const StyledLogoAndNavWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
`;
const StyledNav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 4%;
  ul {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledYoutubeWrapper = styled.div`    
  margin-top: 30px;
  width: 100%;    
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;  
  justify-content: center;
`;

export {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledLogoAndNavWrapper,
  StyledNav,
  StyledBr,
  StyledResponsiveBr,
  StyledYoutubeWrapper
};
